var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"setting-card",class:{
    'setting-card-small': _vm.isSmall,
    'setting-card-medium': _vm.isMedium,
    'setting-card-xs': _vm.isXs,
    'setting-card--jep': _vm.isJEP,
    'setting-card--ce': _vm.isCE,
    'setting-card--lcm': _vm.isLCM,
    'setting-card--gc': _vm.isGC,
  }},[_c('div',{staticClass:"setting-card-container"},[_c('v-card',{attrs:{"outlined":""}},[(_vm.hasBack)?_c('md-button',{staticClass:"md-icon-button setting-card-back-button",on:{"click":function($event){return _vm.$emit('back')}}},[_c('md-icon',{staticClass:"md-primary"},[_vm._v(" keyboard_arrow_left")])],1):_vm._e(),_vm._t("default"),_c('v-card-title',{staticClass:"setting-card-title",domProps:{"innerHTML":_vm._s(_vm.title)}}),(_vm.content)?_c('v-card-text',{staticClass:"setting-card-text",domProps:{"innerHTML":_vm._s(_vm.content)}}):_vm._e(),_vm._t("secondButton"),_vm._t("primaryButton",function(){return [_c('md-button',{staticClass:"md-secondary md-raised setting-card-button",attrs:{"to":_vm.link,"disabled":_vm.disable},on:{"click":function($event){_vm.link ? null : _vm.$emit('onClick')}}},[_vm._v(_vm._s(_vm.buttonText)+" ")])]})],2)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }